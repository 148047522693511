import {HomePage} from "../pages/Home.Page";
import {RouteObject} from "react-router-dom";
import HistoryPage from "../pages/History.Page";
import WhatIsFreemasonryPage from "../pages/WhatIsFreemasonry.Page";
import BecomingAFreemasonPage from "../pages/BecomingAFreemason.Page";
import OfficersPage from "../pages/Officers.Page";

export const BaseRoutes:RouteObject[] = [
    {
        path: '/',
        element: <HomePage />
    },
    {
        path: '/history',
        element: <HistoryPage />
    },
    {
        path: '/history/:slug',
        element: <HistoryPage />
    },
    {
        path: '/whatisfreemasonry',
        element: <WhatIsFreemasonryPage />
    },
    {
        path: '/becomingafreemason',
        element: <BecomingAFreemasonPage />
    },
    {
        path: '/officers',
        element: <OfficersPage />
    },
]