import {IDataService} from "../../core/types/services/IDataService";
import {IEnquiry} from "../../core/types/interfaces/IEnquiry";
import HistoryEventsLocal from "./HistoryEvents.local";
import {ITimelineEvent} from "../../core/types/interfaces/ITimelineEvent";
import OfficersLocal from "./Officers.local";

const DataServiceLocal = ():IDataService => {
    const GetHistory = (slug:string):ITimelineEvent[] => {
        return HistoryEventsLocal;
    }
    
    const GetEnquiries = () => {
        return [];
    }
    
    const SaveEnquiry = (enquiry:IEnquiry) => false;
    
    const GetOfficers = () => {
        return OfficersLocal;
    }
    
    return {
        GetHistory,
        GetEnquiries,
        SaveEnquiry,
        GetOfficers,
    }
}
export default DataServiceLocal;