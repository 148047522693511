import {BasicTemplate} from "../template/Basic.Template";
import {
    Col,
    Container,
    Image,
    Row
} from "react-bootstrap";
import websiteIdentity from "../config/site/WebsiteIdentity";
import LaymanHint from "../components/LaymanHint";

export const HomePage = () => {
    const WMHint = () => (
        <LaymanHint textClass="fw-bold" buttonText={"Worshipful Master"} title={"Worshipful Master"} body={"The person who runs the lodge, this is decided every year by vote."} />
    )
    const OfficersHint = () => (
        <LaymanHint textClass="fw-bold" buttonText={"Officers"} title={"Officers"} body={"Those that help run the lodge, for example, a Secretary, Treasurer and Charity Steward"} />
    )
    return (
        <BasicTemplate>
            <Container fluid>
                <Row>
                    <Col xs={12} sm={"auto"} className="d-flex justify-content-center">
                        <div className="py-4">
                            <Image className={"flex-shrink-0"} fluid src={websiteIdentity.bannerImageName} />
                        </div>
                    </Col>
                    <Col>
                        <p className="fw-bold">
                            The <WMHint />, <OfficersHint /> and Brethren of Royal Brunswick Lodge 296 (Sheffield) in the Province of Yorkshire West Riding would like to welcome you to our website.
                        </p>
                        <p>
                            The Lodge meets at Tapton Masonic Hall, Shore Lane, Sheffield. Regular Lodge meetings start with the
                            Annual Installation meeting on the 2nd Monday in December. We then meet on the 2nd Monday of each
                            month with the exception of July and August. Meetings usually start at 6:15pm.
                        </p>
                        <p>
                            The Lodge meets at Tapton Masonic Hall, Shore Lane, Sheffield. Regular Lodge meetings start with the
                            Annual Installation meeting on the 2nd Monday in December. We then meet on the 2nd Monday of each
                            month with the exception of July and August. Meetings usually start at 6:15pm.
                        </p>
                        <p>
                            The Lodge also meets socially throughout the year with a BBQ, quiz night and annual competition in
                            memory of a sadly departed brother the three main fixed points on our calendar. We also hold more
                            informal social gatherings such as our now traditional pre-Christmas drinks on Eccleshall Road in
                            Sheffield.
                        </p>
                    </Col>
                </Row>
            </Container>
        </BasicTemplate>
    )
}