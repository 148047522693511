import {BasicTemplate} from "../template/Basic.Template";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

const BecomingAFreemason = () => {

    return (
        <BasicTemplate>
            <Helmet><title>Becoming a Freemason</title></Helmet>
            <Container fluid>
                <Row>
                    <Col>
                        <h1>Becoming A Freemason</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Royal Brunswick Lodge is always happy to welcome new members, whether they be brand new
                            Candidates or existing Freemasons from other Lodges.
                        </p>
                        <p>
                            In Royal Brunswick Lodge we pride ourselves on the high standards we maintain-both in the delivery of
                            our Masonic ceremonies and the way we conduct ourselves as men and Masons. This means that we are
                            very demanding in what we expect from new and existing members. If you are a man aged 18 or over,
                            with a belief in a Supreme Being and if you live your life by a strict set of moral principles, always
                            seeking to do the right thing and benefit those around you, then Freemasonry and Royal Brunswick
                            Lodge may well be the place for you. Get in touch by email or through our Instagram page:
                        </p>
                        <p>
                            Once you’ve got in touch we’ll arrange for a couple of the members to meet you at Tapton Hall in
                            Sheffield to show you around and explain a little more about Freemasonry. We then need to get to know
                            you a little better and give you the chance to get to know us, so you’ll be invited to some of the Lodge
                            social events where you can meet and chat with some more of the existing members.
                        </p>
                        <p>
                            If we’re happy with you and you’re happy with us then you’ll be invited to a meeting with some of the
                            senior members of the Lodge. If everyone is happy then at the following meeting you will be proposed for
                            membership.
                        </p>
                        <p>
                            There is no set timescale for this process as it depends on an applicant’s individual circumstances, the
                            Lodge calendar and many other factors. We do not like to rush the process as it is important for us to get
                            to know you and for you to get to know us.
                        </p>
                    </Col>
                </Row>
            </Container>
        </BasicTemplate>
    )
}

export default BecomingAFreemason;