import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Header from "../components/header/Header";

type myType = {
    children?: React.ReactNode
}

export const BasicTemplate = (
    {
        children
    }: myType
) => {
    return (
        <Container className={"min-vh-100 d-flex flex-column"} fluid>
            <Row>
                <Header />
            </Row>
            <Row className="justify-content-center flex-grow-1 align-items-center">
                <Col className="col-md-8">
                    {children}
                </Col>
            </Row>
            <Row>
                
            </Row>
        </Container>
    )
}