import {IFreemason} from "../../core/types/interfaces/IFreemason";
import {Brother, WorshipfulBrother} from "./titles/TitlesCollection.local";
import {MasterMason} from "./degrees/DegreesCollection.local";
import {
    AssistantProvincialGrandMaster,
    PastProvincialAssistantGrandDirectorOfCeremonies,
    PastProvincialGrandRegistrar,
    PastProvincialGrandSuperintendentOfWorks,
    PastProvincialGrandSwordBearer,
    PastProvincialJuniorGrandWarden,
    ProvincialAssistantGrandSecretary,
    ProvincialGrandStandardBearer
} from "./ranks/ProvincialRanksCollection.local";
import {Derbyshire, YorkshireWestRiding} from "./provinces/ProvincesCollection.local";
import {PastJuniorGrandDeacon} from "./ranks/GrandLodgeRanksCollection.local";

export const IJBarnett:IFreemason = {
    title: WorshipfulBrother,
    firstName: "Ian",
    middleNames: ["J"],
    surname: "Barnett",
    degree: MasterMason,
};
export const PHSanderson:IFreemason = {
    title: WorshipfulBrother,
    firstName: "Paul",
    middleNames: ["H"],
    surname: "Sanderson",
    degree: MasterMason,
    provincialRanks: [
        {
            rank: PastProvincialGrandSwordBearer,
            province: YorkshireWestRiding
        }
    ]
};
export const SBooyens:IFreemason = {
    title: Brother,
    firstName: "Stefan",
    surname: "Booyens",
    degree: MasterMason,
};
export const BLittlejohn:IFreemason = {
    title: WorshipfulBrother,
        firstName: "Brian",
    middleNames: ["D"],
    surname: "Littlejohn",
    provincialRanks: [
    {
        rank: AssistantProvincialGrandMaster,
        province: YorkshireWestRiding,
    }
],
    grandLodgeRank: PastJuniorGrandDeacon,
    degree: MasterMason,
};
export const CHousman:IFreemason = {
    title: WorshipfulBrother,
        firstName: "Chris",
    middleNames: ["C"],
    surname: "Housman",
    provincialRanks: [
    {
        rank: ProvincialGrandStandardBearer,
        province: YorkshireWestRiding,
    }
],
    degree: MasterMason,
};
export const MJHeminghway:IFreemason = {
    title: WorshipfulBrother,
        degree: MasterMason,
    firstName: "Martin",
    middleNames: ["J"],
    surname: "Hemingway",
    provincialRanks: [
    {
        rank: ProvincialAssistantGrandSecretary,
        province: Derbyshire,
    }
]
};
export const MBLewis:IFreemason = {
    title: WorshipfulBrother,
        degree: MasterMason,
    firstName: "Mark",
    middleNames: ["B"],
    surname: "Lewis",
    provincialRanks: [
    {
        rank: PastProvincialGrandRegistrar,
        province: YorkshireWestRiding,
    }
]
};
export const JSWarden:IFreemason = {
    title: WorshipfulBrother,
        degree: MasterMason,
    firstName: "John",
    middleNames: ["S"],
    surname: "Warden",
    provincialRanks: [
    {
        rank: PastProvincialJuniorGrandWarden,
        province: YorkshireWestRiding,
    },
    {
        rank: PastProvincialJuniorGrandWarden,
        province: Derbyshire,
    },
]
};
export const PHSwift:IFreemason = {
    title: WorshipfulBrother,
        degree: MasterMason,
    firstName: "Peter",
    middleNames: ["H"],
    surname: "Swift",
    provincialRanks: [
    {
        rank: PastProvincialAssistantGrandDirectorOfCeremonies,
        province: YorkshireWestRiding,
    }
]
};
export const ADTaylor:IFreemason = {
    title: Brother,
        degree: MasterMason,
    firstName: "Andy",
    middleNames: ["D"],
    surname: "Taylor",
};
export const RHague:IFreemason = {
    title: Brother,
        degree: MasterMason,
    firstName: "Ryan",
    surname: "Hague"
};
export const SBatty:IFreemason = {
    title: Brother,
        degree: MasterMason,
    firstName: "Steve",
    surname: "Batty"
};
export const MIqbal:IFreemason = {
    title: Brother,
        degree: MasterMason,
    firstName: "Maroof",
    surname: "Iqbal"
};
export const JPanasiuk:IFreemason = {
    title: Brother,
        degree: MasterMason,
    firstName: "Joseph",
    surname: "Panasiuk",
};
export const APerry:IFreemason = {
    title: WorshipfulBrother,
        degree: MasterMason,
    firstName: "Alan",
    surname: "Perry",
    provincialRanks: [
    {
        rank: PastProvincialGrandSuperintendentOfWorks,
        province: YorkshireWestRiding,
    }
]
};

const MembersLocal:IFreemason[] = [
    IJBarnett,
    PHSanderson,
    SBooyens,
    RHague,
    ADTaylor,
    APerry,
    JPanasiuk,
    MIqbal,
    SBatty,
    PHSwift,
    JSWarden,
    MBLewis,
    MJHeminghway,
    CHousman,
    BLittlejohn
]

export default MembersLocal;