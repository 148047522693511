import {BasicTemplate} from "../template/Basic.Template";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

const WhatIsFreemasonryPage = () => {
    
    return (
        <BasicTemplate>
            <Helmet><title>What is Freemasonry?</title></Helmet>
            <Container fluid>
                <Row>
                    <Col>
                        <h1>What Is Freemasonry?</h1> 
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            Freemasonry is one of the oldest social and charitable organisations in the world. It is a society for men of
                            any religion or undefined faith who live their lives by a strict code of moral values and principles. We
                            take the tools and traditions of medieval stonemasons and attach allegorical and metaphorical meanings to
                            them in order to teach us how to be better men and members of society. 
                        </p>
                        <p>
                            Take for example the square. A stone mason uses this to measure whether an angle is 90° as they know
                            that is the angle the square is calibrated to. To a Freemason the square is calibrated according to the
                            righteousness of our actions and so we measure our actions using the square; are we doing the right thing?
                        </p>
                        <p>
                            Freemasonry is not a religion, nor does it seek to replace or even supplement a member’s religion. Each
                            member deals in private with their own personal relationship with their god or gods, Freemasonry simply
                            offers an opportunity for all men with a belief in a higher power or supreme being to come together and
                            learn how to be better men.
                        </p>
                    </Col>
                </Row>
            </Container>
        </BasicTemplate>
    )
}

export default WhatIsFreemasonryPage;