import DataServiceLocal from "../../data/local/DataService.local";
import {BasicTemplate} from "../template/Basic.Template";
import {Col, Container, Row} from "react-bootstrap";
import OfficerCard from "../components/officers/Officer.Card";
import {Helmet} from "react-helmet";

const OfficersPage = () => {
    const {GetOfficers} = DataServiceLocal();
    return (
        <BasicTemplate>
            <Helmet><title>Royal Brunswick Officers</title></Helmet>
            <Container className={"px-2"} fluid>
                <Row>
                    <Col>
                        <h1 className={"pt-2"}>Officers</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {GetOfficers().map((o) => (
                            <OfficerCard {...o} />
                        ))}
                    </Col>
                </Row>
            </Container>
        </BasicTemplate>
    )
}

export default OfficersPage;