import {IRank} from "../../../core/types/interfaces/IRank";

export const MasterMason:IRank = {
    name: "Master Mason",
    abbreviation: "MM",
    laymanDescription: "A third degree Freemason, the highest degree in a craft (general) lodge. Other orders have higher degrees, these are optional and don't influence a craft lodge."
}

export const FellowCraft:IRank = {
    name: "Fellowcraft",
    abbreviation: "FC",
    laymanDescription: "A second degree Freemason."
}

export const EnteredApprentice:IRank = {
    name: "Entered Apprentice",
    abbreviation: "EA",
    laymanDescription: "A first degree Freemason, a newly joined member."
}

export const DegreesLocal:IRank[] = [
    MasterMason,
    FellowCraft,
    EnteredApprentice,
]