import {ITimelineEvent} from "../../../core/types/interfaces/ITimelineEvent";
import {Button, Card, Tab} from "react-bootstrap";
import DateTimeFormatterText from "./DateTimeFormatter.Text";
import {useState} from "react";
import HistoryEventSource from "./HistoryEvent.Source";
import {MdMoreVert} from "react-icons/md";

const HistoryEventCard = (props:ITimelineEvent) => {
    const [key, setKey] = useState<"event" | "meta">("event");
    return (
        <Card className={"my-3 border-0 border-bottom border-dark rounded-0"}>
            <Tab.Container
                id={props.date.toISOString()}
                defaultActiveKey={"event"}
                activeKey={key}
            >
                <Card.Header
                    className={"text-center fw-bold bg-highlight"}
                    onClick={() => {
                        if(key === "event") setKey("meta");
                        if(key === "meta") setKey("event");
                    }}
                >
                    <DateTimeFormatterText date={props.date} showMonth={props.showMonth} showDay={props.showDay} />
                    <MdMoreVert size={25} className={"position-absolute end-0"} />
                </Card.Header>
                <Tab.Content>
                    <Tab.Pane eventKey={"event"}>
                        <Card.Body>
                            <Card.Title className={"text-center"}>
                                {props.title}
                            </Card.Title>
                            <Card.Text dangerouslySetInnerHTML={{__html:props.description}} />
                        </Card.Body>
                    </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                    <Tab.Pane eventKey={"meta"}>
                        <Card.Body>
                            {(props.author || props.source_date || props.name || props.link) && (
                                <>
                                    <Card.Title className={"text-center"}>
                                    Details
                                    </Card.Title>
                                    <Card.Text className={"text-center"}>
                                        <HistoryEventSource {...props} />
                                    </Card.Text>
                                </>
                                )}
                            <Card.Title className={"text-center"}>
                                Tags
                            </Card.Title>
                            <Card.Text className={"text-center"}>
                                {props.tags.map((tag) => <Button size={"sm"} href={`/history/${tag.slug}`}>{tag.display_name}</Button>)}
                            </Card.Text>
                        </Card.Body>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Card>
    )
}


export default HistoryEventCard