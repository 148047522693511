import {Nav, NavDropdown} from "react-bootstrap";

type MyProps = {
    linkclasses?: string;
}

const HeaderMenu = (props: MyProps) => (
    <Nav
        className={"align-items-center"}
    >
        <Nav.Link className={props.linkclasses} href="/">Home</Nav.Link>
        {/*<Nav.Link disabled>Meetings</Nav.Link>*/}
        <Nav.Link className={props.linkclasses} href="/officers">Officers</Nav.Link>
        <Nav.Link className={props.linkclasses} href="/history">History</Nav.Link>
        {/*<Nav.Link disabled>Meeting Hall</Nav.Link>*/}
        <NavDropdown className={props.linkclasses} title="Becoming A Freemason">
            <NavDropdown.Item className={props.linkclasses} href="/whatisfreemasonry">What Is Freemasonry?</NavDropdown.Item>
            <NavDropdown.Item className={props.linkclasses} href="/becomingafreemason">Steps To Becoming A Freemason</NavDropdown.Item>
            <NavDropdown.Item className={props.linkclasses} disabled>The Ideal Candidate</NavDropdown.Item>
            <NavDropdown.Item className={props.linkclasses} disabled>What To Expect</NavDropdown.Item>
        </NavDropdown>
        {/*<Nav.Link disabled>Masonic Links</Nav.Link>*/}
        {/*<Nav.Link disabled>Lodge News</Nav.Link>*/}
        {/*<Nav.Link disabled className="bg-primary text-white">Contact Us</Nav.Link>*/}
    </Nav>
)

export default HeaderMenu;