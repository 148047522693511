import {ITimelineEvent} from "../../../core/types/interfaces/ITimelineEvent";

const HistoryEventSource = (source: Pick<ITimelineEvent, "author" | "name" | "link" | "source_date">) => {
    return (
        <>
            {source.author && <p>Author: {source.author}</p>}
            {source.name && <p>Name: {source.name}</p>}
            {source.link && <p>Link: {source.link}</p>}
            {source.source_date && <p>Source Date: {source.source_date}</p>}
        </>
    )
}

export default HistoryEventSource;