import {IOfficer} from "../../../core/types/interfaces/IOfficer";
import {Card} from "react-bootstrap";
import LaymanHint from "../LaymanHint";
import AbbreviatedOfficerText from "./AbbreviatedOfficer.Text";

const OfficerCard = (officer: IOfficer) => (
    <Card className={"my-3 border-0 border-bottom border-dark rounded-0"}>
        <Card.Header className={"bg-highlight"}>
            <LaymanHint buttonText={officer.office.name} title={officer.office.name} body={officer.office.laymanDescription} showIcon={true} />
        </Card.Header>
        <Card.Body>
            <Card.Text><AbbreviatedOfficerText {...officer.person} /></Card.Text>
        </Card.Body>
    </Card>
)

export default OfficerCard;