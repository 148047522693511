import {IOffice} from "../../../core/types/interfaces/IOffice";

export const WorshipfulMaster : IOffice = {
    name: "Master",
    abbreviation: "WM",
    laymanDescription: "The ruler of a lodge, elected on a yearly basis"
}
export const SeniorWarden : IOffice = {
    name: "Senior Warden",
    abbreviation: "SW",
    laymanDescription: ""
}
export const JuniorWarden : IOffice = {
    name: "Junior Warden",
    abbreviation: "JW",
    laymanDescription: ""
}
export const Chaplain : IOffice = {
    name: "Chaplain",
    abbreviation: "Chap",
    laymanDescription: ""
}
export const Secretary : IOffice = {
    name: "Secretary",
    abbreviation: "Sec",
    laymanDescription: ""
}
export const Treasurer : IOffice = {
    name: "Treasurer",
    abbreviation: "Treas",
    laymanDescription: ""
}
export const DirectorofCeremonies : IOffice = {
    name: "Director of Ceremonies",
    abbreviation: "DC",
    laymanDescription: ""
}
export const Almoner : IOffice = {
    name: "Almoner",
    abbreviation: "Alm",
    laymanDescription: ""
}
export const CharitySteward : IOffice = {
    name: "Charity Steward",
    abbreviation: "ChStwd",
    laymanDescription: ""
}
export const SeniorDeacon : IOffice = {
    name: "Senior Deacon",
    abbreviation: "SD",
    laymanDescription: ""
}
export const JuniorDeacon : IOffice = {
    name: "Junior Deacon",
    abbreviation: "JD",
    laymanDescription: ""
}
export const AssistantDirectorofCeremonies : IOffice = {
    name: "Assistant Director of Ceremonies",
    abbreviation: "ADC",
    laymanDescription: ""
}
export const Steward : IOffice = {
    name: "Steward",
    abbreviation: "Stwd",
    laymanDescription: ""
}
export const InnerGuard : IOffice = {
    name: "Inner Guard",
    abbreviation: "IG",
    laymanDescription: ""
}
export const Organist : IOffice = {
    name: "Organist",
    abbreviation: "Org",
    laymanDescription: ""
}
export const Tyler : IOffice = {
    name: "Tyler",
    abbreviation: "Tyler",
    laymanDescription: ""
}
export const ImmediatePastMaster : IOffice = {
    name: "Immediate Past Master",
    abbreviation: "IPM",
    laymanDescription: "The previous master of the lodge."
}
export const Mentor : IOffice = {
    name: "Mentor",
    abbreviation: "Mentor",
    laymanDescription: ""
}
export const MembershipOfficer : IOffice = {
    name: "Membership Officer",
    abbreviation: "MOff",
    laymanDescription: ""
}
export const OfficesLocal:IOffice[] = [
    WorshipfulMaster,
    SeniorWarden,
    JuniorWarden,
    Chaplain,
    Secretary,
    Treasurer,
    DirectorofCeremonies,
    Almoner,
    CharitySteward,
    SeniorDeacon,
    JuniorDeacon,
    AssistantDirectorofCeremonies,
    Steward,
    InnerGuard,
    Organist,
    Tyler,
    ImmediatePastMaster,
    Mentor,
]