import {RouteObject, useRoutes} from "react-router-dom";
import {BaseRoutes} from "./routes/Base.Routes";

const routes:RouteObject[] = [...BaseRoutes];

const AppRouter = () => {
    let element = useRoutes(routes);
    return (
        <>
            {element}
        </>
    );
}

export default AppRouter;