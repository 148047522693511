import {IOfficer} from "../../core/types/interfaces/IOfficer";
import {
    Almoner, AssistantDirectorofCeremonies, CharitySteward,
    DirectorofCeremonies,
    ImmediatePastMaster, InnerGuard, JuniorDeacon,
    JuniorWarden, MembershipOfficer, Mentor, Secretary, SeniorDeacon,
    SeniorWarden, Steward,
    Treasurer, Tyler,
    WorshipfulMaster
} from "./offices/OfficesCollection.local";
import {
    ADTaylor, APerry,
    BLittlejohn,
    CHousman,
    IJBarnett, JPanasiuk,
    JSWarden,
    MBLewis, MIqbal,
    MJHeminghway,
    PHSanderson, PHSwift, RHague, SBatty,
    SBooyens
} from "./Members.local";

const OfficersLocal:IOfficer[] = [
    {
        office: WorshipfulMaster,
        person: IJBarnett
    },
    {
        office: SeniorWarden,
        person: PHSanderson
    },
    {
        office: JuniorWarden,
        person: SBooyens
    },
    {
        office: ImmediatePastMaster,
        person: BLittlejohn
    },
    {
        office: Treasurer,
        person: CHousman,
    },
    {
        office: Secretary,
        person: MJHeminghway,
    },
    {
        office: DirectorofCeremonies,
        person: MBLewis,
    },
    {
        office: Almoner,
        person: JSWarden,
    },
    {
        office: CharitySteward,
        person: PHSwift
    },
    {
        office: Mentor,
        person: CHousman
    },
    {
        office: SeniorDeacon,
        person: ADTaylor
    },
    {
        office: JuniorDeacon,
        person: RHague
    },
    {
        office: AssistantDirectorofCeremonies,
        person: SBatty
    },
    {
        office: InnerGuard,
        person: MIqbal
    },
    {
        office: MembershipOfficer,
        person: ADTaylor
    },
    {
        office: Steward,
        person: JPanasiuk
    },
    {
        office: Tyler,
        person: APerry
    }
]

export default OfficersLocal;