import {IRank} from "../../../core/types/interfaces/IRank";

export const PastProvincialGrandSwordBearer: IRank = {
    name: "Past Provincial Grand Sword Bearer",
    abbreviation: "PPGSwdB",
    laymanDescription: ""
}

export const AssistantProvincialGrandMaster: IRank = {
    name: "Assistant Provincial Grand Master",
    abbreviation: "AsstProvGM",
    laymanDescription: "",
}

export const ProvincialGrandStandardBearer: IRank = {
    name: "Provincial Grand Standard Bearer",
    abbreviation: "ProvGStdBr",
    laymanDescription: "",
}

export const ProvincialAssistantGrandSecretary: IRank = {
    name: "Provincial Assistant Grand Secretary",
    abbreviation: "ProvAGSec",
    laymanDescription: ""
}

export const PastProvincialGrandRegistrar: IRank = {
    name: "Past Provincial Grand Registrar",
    abbreviation: "PPGReg",
    laymanDescription: ""
}

export const PastProvincialJuniorGrandWarden: IRank = {
    name: "Past Provincial Junior Grand Warden",
    abbreviation: "PPJGW",
    laymanDescription: ""
}
export const PastProvincialAssistantGrandDirectorOfCeremonies: IRank = {
    name: "Past Provincial Assistant Grand Director Of Ceremonies",
    abbreviation: "PPAGDC",
    laymanDescription: "",
}
export const PastProvincialGrandSuperintendentOfWorks: IRank = {
    name: "Past Provincial Grand Superintendent Of Works",
    abbreviation: "PPGSuptWks",
    laymanDescription: ""
}

export const ProvincialRanksLocal:IRank[] = [
    PastProvincialGrandSwordBearer,
    AssistantProvincialGrandMaster,
    ProvincialGrandStandardBearer,
    PastProvincialAssistantGrandDirectorOfCeremonies,
    ProvincialAssistantGrandSecretary,
    PastProvincialGrandRegistrar,
    PastProvincialJuniorGrandWarden,
    PastProvincialGrandSuperintendentOfWorks
]