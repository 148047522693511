import {BasicTemplate} from "../template/Basic.Template";
import {Col, Container, Row} from "react-bootstrap";
import DataServiceLocal from "../../data/local/DataService.local";
import HistoryEventCard from "../components/history/HistoryEvent.Card";
import {Helmet} from "react-helmet";

const HistoryPage = () => {
    const { GetHistory } = DataServiceLocal();
    return (
        <BasicTemplate>
            <Helmet><title>Royal Brunswick History</title></Helmet>
            <Container className={"px-2"} fluid>
                <Row>
                    <Col>
                        <h1 className={"pt-2"}>History</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {GetHistory("history").map((e) => (
                            <HistoryEventCard
                                key={e.date.toString() + e.title}
                                {...e}
                            />
                            )
                        )}
                    </Col>
                </Row>
            </Container>
        </BasicTemplate>
    )
}

export default HistoryPage;