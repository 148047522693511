import {IRank} from "../../../core/types/interfaces/IRank";

export const WorshipfulBrother: IRank = {
    name: "Worshipful Brother",
    abbreviation: "W Bro",
    laymanDescription: "A Freemason who has previously ruled a lodge."
}

export const Brother: IRank = {
    name: "Brother",
    abbreviation: "Bro",
    laymanDescription: "A Freemason who has yet to rule a lodge."
}

export const TitlesLocal:IRank[] = [
    WorshipfulBrother,
    Brother,
]