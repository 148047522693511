import {IProvince} from "../../../core/types/interfaces/IProvince";

export const YorkshireWestRiding:IProvince = {
    name: "Yorkshire West Riding",
    abbreviation: "YWR",
};

export const Derbyshire:IProvince = {
    name: "Derbyshire",
    abbreviation: "Derbys",
}

export const ProvincesLocal:IProvince[] = [
    YorkshireWestRiding,
    Derbyshire,
]