import {IRank} from "../../../core/types/interfaces/IRank";

export const PastJuniorGrandDeacon:IRank = {
    name: "Past Junior Grand Deacon",
    abbreviation: "PJGD",
    laymanDescription: "",
}

export const GrandLodgeRanksLocal:IRank[] = [
    PastJuniorGrandDeacon,
]