import {Col, Row} from "react-bootstrap";

const HeaderTitle = () => (
    <Row className={"align-items-center px-3"}>
        <Col>
            <Row className="justify-content-center">Royal</Row>
            <Row>Brunswick</Row>
        </Col>
        <Col>
            <h1 className={"h-75 d-flex align-items-center border-bottom border-top border-dark mb-0 py-1"}>296</h1>
        </Col>
    </Row>
)
export default HeaderTitle;