import {Button, OverlayTrigger, Popover, PopoverBody, PopoverHeader} from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';

type MyProps = {
    buttonText: string,
    title: string,
    body: string,
    textClass?: string,
    showIcon?: boolean
}

const LaymanHint = (props: MyProps) => {
    const thisPopover = (
        <Popover>
            <PopoverHeader>{props.title}</PopoverHeader>
            <PopoverBody>{props.body}</PopoverBody>
        </Popover>
    );
    if(props.body.trim().length < 1) return <>{props.buttonText}</>;
    return (
        <OverlayTrigger trigger={["hover", "click"]} overlay={thisPopover} rootClose={true}>
            <Button className={"p-0 align-baseline text-black " + props.textClass} style={{textDecorationStyle:"dotted"}} variant="link">{props.buttonText}{props.showIcon ? <Icon.InfoLg className="align-top" /> : ''}</Button>
        </OverlayTrigger>
    )
}

export default LaymanHint;