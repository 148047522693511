import {ITimelineEvent, ITimelineSlug} from "../../core/types/interfaces/ITimelineEvent";
import {ListGroup} from "react-bootstrap";
import {renderToString} from "react-dom/server";

const DefaultHistoryTag:ITimelineSlug = {
    slug: "history",
    display_name: "History"
}

const HistoryEventsLocal: ITimelineEvent[] = [
    {
        title: "Origin",
        date: new Date("01 Jan 1772"),
        showMonth: false,
        showDay: false,
        description: "Royal Brunswick Lodge was founded in 1793 by thirteen members of Lodge No. 72, which itself was\n" +
            "founded in 1772. The first Worshipful Master was W.Bro. James Woollen who served as Master for a\n" +
            "total of thirteen years between 1793 and 1812.",
        tags: [DefaultHistoryTag],
        author: "Ryan Hague",
    },
    {
        title: "The Name",
        date: new Date("01 Jan 1793"),
        showMonth: false,
        showDay: false,
        description: "Nobody is quite sure why those founding Brethren chose the name Royal Brunswick but most believe it\n" +
            "was probably a tribute to the Grand Master at the time, the Prince of Wales George Augustus Frederick,\n" +
            "later to become King George IV. Prince George was Electoral Prince of Brunswick Luneburg and married\n" +
            "Caroline of Brunswick so this theory seems quite a sensible one.\n",
        tags: [DefaultHistoryTag]
    },
    {
        title: "Revival",
        date: new Date("01 Nov 1842"),
        showMonth: true,
        showDay: false,
        description: "The Lodge weathered many storms throughout the first century of its existence with Britain being at war\n" +
            "with France and the fears of invasion or revolution which came with it, as well as the labour and trade\n" +
            "problems caused by such a long war. By the middle of the 19 th Century membership numbers had\n" +
            "dwindled and the Lodge’s future seemed anything but certain. In November 1842 W.Bro. Martin de\n" +
            "Bartolomé joined Royal Brunswick from Britannia Lodge and was soon followed by five more joining\n" +
            "members. W.Bro. Bartolomé is one of the real heroes of Freemasonry in Sheffield and the Lodge’s steady\n" +
            "return to more healthy membership numbers can be attributed in large part to his ingenuity and strength of\n" +
            "character.",
        tags: [DefaultHistoryTag]
    },
    {
        title: "First World War",
        date: new Date("1914"),
        showMonth: false,
        showDay: false,
        description: renderToString(
            <>
                <p>
                    During the First World War eleven members of Royal Brunswick Lodge saw active service, their names
                    are recorded below:
                </p>
                <ListGroup>
                    <ListGroup.Item>
                        Cpt. V.P. Belcher Died of wounds 5 th August 1918
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Cpt. H.B. Bates
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Lt. E.B. Arnison Killed in action 18 th August 1918
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Lt. C.M. Bostock
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Lt. M. Bright
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Lt. L.H. Else
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Lt. F. Else
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Lt. S.C. Else Severely wounded 10 th July 1916
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Lt. A.E. Quine
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Sgt. H. Neil Killed in action July 1916
                    </ListGroup.Item>
                    <ListGroup.Item>
                        Spr. C. Hampton
                    </ListGroup.Item>
                </ListGroup>
                <p>
                    Following the war the Lodge contributed to the Masonic Million Fund which raised money to build
                    Freemasons Hall in London as a memorial to all of the Brethren who gave their lives during the conflict.
                    Having donated over an average of ten guineas per member, the Lodge qualified for status as a Hall Stone
                    Lodge and successive Masters since 1922 have worn the jewel pictured.
                </p>
            </>
        ),
        tags: [DefaultHistoryTag]
    },
]

export default HistoryEventsLocal;