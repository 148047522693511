import {
    Col,
    Navbar,
    NavbarBrand,
    OffcanvasBody,
    OffcanvasHeader,
    OffcanvasTitle,
} from "react-bootstrap";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import NavbarOffcanvas from "react-bootstrap/NavbarOffcanvas";
import HeaderMenu from "./Header.Menu";
import HeaderTitle from "./Header.Title";
import {useState} from "react";
import {BsXLg} from "react-icons/bs";

const HeaderNavbar = () => {
    const toggleControls = "offCanvasNavbar";
    const offCanvasLabelledBy = "offCanvasNavbarLabel";
    const [show, setShow] = useState<boolean>(false);
    return (
        <Navbar collapseOnSelect expand="md" variant="light" className="py-0 border-bottom border-highlight">
            <NavbarBrand href="/" className="">
                <HeaderTitle />
            </NavbarBrand>
            <Col className={"d-none d-md-block"}>
                <HeaderMenu />
            </Col>
            <NavbarToggle aria-controls={toggleControls} className="h-100 rounded-0 border-0" onClick={() => setShow(true)} />
            <NavbarOffcanvas
                id={toggleControls}
                aria-labelledby={offCanvasLabelledBy}
                placement="start"
                className={"vh-100 bg-highlight"}
                show={show}
            >
                <OffcanvasHeader
                    className="border-bottom border-white py-0 pe-0"
                >
                    <OffcanvasTitle id={offCanvasLabelledBy} className="p-2">
                        <HeaderTitle />
                    </OffcanvasTitle>
                    <div onClick={() => setShow(false)} className={"p-3 text-white h-100 d-flex align-items-center pe-4 fs-3"}>
                        <BsXLg />
                    </div>
                </OffcanvasHeader>
                <OffcanvasBody
                    className={"d-flex justify-content-center align-items-center"}
                >
                    <HeaderMenu linkclasses={"fs-3 fs-md-6 text-center"} />
                </OffcanvasBody>
            </NavbarOffcanvas>
        </Navbar>
    )
}

export default HeaderNavbar;