import {IFreemason} from "../../../core/types/interfaces/IFreemason";
import LaymanHint from "../LaymanHint";

const AbbreviatedOfficerText = (officer: IFreemason) => (
    <>
        <LaymanHint buttonText={officer.title.abbreviation} title={officer.title.name} body={officer.title.laymanDescription} />
        {' ' + officer.firstName.charAt(0)}.
        {officer.middleNames ? officer.middleNames.map((n) => <> {n}.</>) : ''} 
        {' ' + officer.surname}
        {officer.provincialRanks?.map((r) => <>, <LaymanHint buttonText={r.rank.abbreviation} title={r.rank.name} body={r.rank.laymanDescription} />{r.province.abbreviation === "Derbys" ? <>({r.province.abbreviation})</> : ''}</>)}
        {officer.grandLodgeRank ? <>, <LaymanHint buttonText={officer.grandLodgeRank.abbreviation} title={officer.grandLodgeRank.name} body={officer.grandLodgeRank.laymanDescription} /></> : ''}
    </>
)


export default AbbreviatedOfficerText;