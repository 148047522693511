import './web/styles/scss/default.scss';
import {BrowserRouter as Router} from "react-router-dom";
import {ThemeProvider} from "react-bootstrap";
import AppRouter from "./web/AppRouter";

const App = () => {

    return (
        <Router>
            <ThemeProvider>
                <AppRouter />
            </ThemeProvider>
        </Router>
    )
}

export default App;
